import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MoonPayProvider, MoonPayBuyWidget  } from '@moonpay/moonpay-react';
import * as selectors from '../../../../store/selectors';
import { useSelector } from 'react-redux';

function MoonPayModal({showModal, setShowModal, amount, priceTransak, tokenType, fiat, payment}) {
  const {t}=useTranslation();
  const signedUser = useSelector(selectors.user);

  return (
    <div>
      <MoonPayProvider 
        apiKey={process.env.REACT_APP_MOONPAY_KEY} 
        debug
      >
        <MoonPayBuyWidget
          variant="overlay"
          baseCurrencyCode={fiat === 0 ? 'usd' : 'eur'}
          baseCurrencyAmount={amount}
          defaultCurrencyCode={tokenType === 0 ? "USDC_CCHAIN" : "USDC_AVAX"}
          onCloseOverlay={() => setShowModal(false)}
          walletAddress={signedUser.metamask}
          email={signedUser.email}
          visible={showModal}
          paymentMethod={payment === 0 ? 'credit_debit_card' : (
            fiat === 0 ? 'gbp_bank_transfer' : 'sepa_bank_transfer'
          )}
        />
      </MoonPayProvider>
    </div>
  )
}

export default MoonPayModal