import React from 'react';
import { Transak } from '@transak/transak-sdk';
import TransakImg from "../../../../assets/images/transak-1.png"
import * as selectors from '../../../../store/selectors';
import { useSelector } from 'react-redux';

const TransakIntegration = ({amount, priceTransak, tokenType, fiat, payment}) => {
	const signedUser = useSelector(selectors.user);

  const openTransak = () => {
    let transak = new Transak({
      apiKey: process.env.REACT_APP_TRANSAK_KEY, // Replace with your Transak API key
      environment: process.env.REACT_APP_TRANSAK_ENVIRONMENT, // Use 'PRODUCTION' when ready
      defaultCryptoCurrency: tokenType === 0 ? 'USDC' : 'AVAX', // Example: Default cryptocurrency to purchase
      walletAddress: signedUser.metamask, // Optional: User's wallet address
      defaultFiatCurrency: fiat === 0 ? 'USD' : 'EUR', // Optional: Default fiat currency
			defaultFiatAmount: amount,
			defaultPaymentMethod: payment === 0 ? 'credit_debit_card' : (
				fiat === 0 ? 'pm_us_wire_bank_transfer' : 'sepa_bank_transfer'
			),
      // email: '', // Optional: User's email
      hostURL: window.location.origin, // Required: Current URL
      widgetHeight: '650px',
      widgetWidth: '450px',
			network: 'avaxcchain'
    });

    transak.init();
  };

  return (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', border: '4px solid #a706aa', borderRadius: 20, margin: 15, cursor: 'pointer'}} onClick={() => openTransak()}>
			<img src={TransakImg} style={{height: 70}} alt="" />
			<div>
				<p style={{margin: 5, color: 'black', fontWeight: 600, fontSize: 20}}>{priceTransak}{tokenType === 0 ? ' USDC' : ' AVAX'}</p>
				<p>{`≃ ${amount} ${fiat === 0 ? 'USD' : 'EUR'}`}</p>
			</div>
		</div>
  );
};

export default TransakIntegration;