import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { useLocation, useHistory } from "react-router-dom";
import { EPATHS } from '../routes/paths.enum';

import HomePage from './Home/homePage';
import Explore from './MarkePlace/pages/Explore/';
import Land from './MarkePlace/pages/Land/';
import MyNFTs from './MarkePlace/pages/MyNFTs';
import Collections from './MarkePlace/pages/Collections';
import CollectionHistory from './MarkePlace/pages/Collections/collectionHistory';
import CollectionDetail from './MarkePlace/pages/Collections/collectionDetail';
import EditItem from './MarkePlace/pages/MyNFTs/editItem';
import Authors from './MarkePlace/pages/Authors/authors';
import Author from './MarkePlace/pages/Authors/author';
import Wallets from './MarkePlace/pages/Wallet/wallets'; 
import Profile from './MarkePlace/pages/Profile';
import CreateOption from './MarkePlace/pages/Wallet/createOptions';
import CreateMultiple from './MarkePlace/pages/Wallet/createMultiple';
import Register from './MarkePlace/pages/Register/';
import CryptoExchange from './MarkePlace/pages/CryptoExchange';
import { StyledHeader } from './MarkePlace/Styles';
import Header from './MarkePlace/menu/header';
import MyProfile from './MarkePlace/pages/Profile/myProfile';
import Gain from './MarkePlace/pages/Gain/gain';
import Coupon from './MarkePlace/pages/Coupon/coupon';
import CouponDetail from './MarkePlace/pages/Coupon/couponDetail';
// import UsersList from './MarkePlace/pages/Users/usersList';
// import CreateUser from './MarkePlace/pages/Users/createUser';
import Settings from './MarkePlace/pages/Settings/settings';
import AssetDetails from './MarkePlace/pages/Explore/AssetDetails';
import TileDetails from './MarkePlace/pages/Land/TileDetails';
import TileGroupDetails from './MarkePlace/pages/Land/TileGroupDetails';
import ItemDetails from './MarkePlace/pages/Explore/ItemDetails';
import GetStarted from './MarkePlace/pages/GetStarted/GetStarted';
import Transactions from './MarkePlace/pages/Transactions';
import Map from './Map';
import TileMap from './TileMap';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useSelector, useDispatch } from 'react-redux';
import * as selectors from '../store/selectors';
import Notifications from './MarkePlace/pages/Notifications';
import MyBids from './MarkePlace/pages/Bids';
import ModalRegister from './Modals/modalRegister';
import { fetchAuthorList } from "../store/actions/thunks";
import { login, logout } from "../utils/functions/authenticate";
import * as actions from '../store/actions';
import CodeVerification from './Modals/codeVerification';
import ResetPassword from './MarkePlace/pages/resetPassword';
import ConfirmPassword from './MarkePlace/pages/confirmPassword';
import ChangeEmail from './MarkePlace/pages/changeEmail';
import * as AuthService from "../services/AuthService";
import ScrollToTop from './ScrollToTop';
import AdminAction from './MarkePlace/pages/AdminAction';
//SWITCH VARIABLE FOR PAGE STYLE
const theme = 'GREY'; //LIGHT, GREY, RETRO

declare global {
  interface Window{
    ethereum?: any
  }
}

function Main() {
  const location = useLocation();
  const loadingState = useSelector(selectors.loadingState);
  const [isActive, setActive] = useState(false);
  const stroageUser = localStorage.getItem('_user') === null ? '{}' : localStorage.getItem('_user') + '';
  const isSnapshot = localStorage.getItem('_user') 
  && JSON.parse(stroageUser).isSnapshot ? true : false;
  const isAuthenticated = localStorage.getItem('_user') 
    && JSON.parse(stroageUser).isVerified ? true : false;
  const isAdmin = localStorage.getItem('_user') 
  && JSON.parse(stroageUser).roleId == 1 ? true : false;
  const [showRegisterModal, setShowRegisterModal] = useState(JSON.parse(stroageUser).isVerified === false);
  const [showResendModal, setShowResendModal] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const formData = new FormData();
  formData.append("metamask", JSON.parse(stroageUser).metamask);

  useEffect(() => {
    dispatch(fetchAuthorList());
    if (window.ethereum){
      window.ethereum.on('accountsChanged', function() {
        if(isAuthenticated) {
          logout(dispatch, actions.setUser, history).then(() => {
            login(dispatch, actions.setLoading, actions.setUser, history).then(() => 
            {
              window.location.reload();
            });
          });
        }
        else {
          login(dispatch, actions.setLoading, actions.setUser, history).then(() => 
          {
            window.location.reload();
          });
        }
      })
    }
  }, [])

  useEffect(() => {
    if (!loadingState.loading)
    {
      setActive(loadingState);
    }
    if(location.pathname===EPATHS.HOME) {
      setShowRegisterModal(JSON.parse(stroageUser).isVerified === false);
    }
  }, [loadingState])

  useEffect(() => {
    setShowRegisterModal(JSON.parse(stroageUser).isVerified === false);
  }, [JSON.parse(stroageUser).isVerified]);
  
  return <div>    
    <StyledHeader theme={theme} />
    <LoadingOverlay
      active={isActive}
      className='centeredOverlay'
      spinner
    >  
  {/* {(
    location.pathname!==EPATHS.HOME &&
    location.pathname!==EPATHS.EXPLORE &&
    location.pathname!==EPATHS.AUTHORS &&
    location.pathname!==EPATHS.MY_COLLECTIONS &&
    location.pathname!==EPATHS.MY_NFTS &&
    location.pathname!==EPATHS.MAPS &&
    location.pathname!==EPATHS.WALLET &&
    location.pathname!==EPATHS.LAND &&
    location.pathname!==EPATHS.TRANSACTIONS &&
    location.pathname!==EPATHS.GAIN &&
    location.pathname!==EPATHS.COUPON &&
    location.pathname!==EPATHS.IN_GAME_WALLET &&
    location.pathname!==EPATHS.RESET_PASSWORD &&
    location.pathname!==EPATHS.CONFIRM_PASSWORD &&
    location.pathname!==EPATHS.CHANGE_EMAIL &&
    location.pathname!==EPATHS.MY_COLLECTION_HISTORY &&
    location.pathname!==EPATHS.MY_COLLECTION_DETAIL &&
    location.pathname!==`/3D_Asset/${localStorage.getItem("id")}` ) &&
    <Header className=""/>} */}
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path={EPATHS.HOME} component={HomePage} />
        <Route exact path={EPATHS.EXPLORE} component={Explore} />
        <Route exact path={EPATHS.LAND} component={Land} />
        <Route exact path={EPATHS.ASSET_DETAIL} component={AssetDetails} />
        <Route exact path={EPATHS.LAND_DETAIL} component={TileDetails} />
        <Route exact path={EPATHS.LAND_GROUP_DETAIL} component={TileGroupDetails} />
        <Route exact path={EPATHS.NFT_DETAIL} component={ItemDetails} />
        <Route exact path={EPATHS.AUTHORS} component={Authors} />
        <Route exact path={EPATHS.AUTHOR} component={Author} />
        <Route exact path={EPATHS.WALLET} component={Wallets} />
        {/* <Route exact path={EPATHS.MAPS} component={Maps} /> */}
        <Route exact path={EPATHS.MAP} component={Map} />
        <Route exact path={EPATHS.RESET_PASSWORD} component={ResetPassword} />
        <Route exact path={EPATHS.CHANGE_EMAIL} component={ChangeEmail} />
        <Route exact path={EPATHS.CONFIRM_PASSWORD} component={ConfirmPassword} />
        <Route exact path={EPATHS.TILE_MAP} component={TileMap} />
        <Route exact path={EPATHS.GET_STARTED} component={GetStarted} />
        <Route exact path={EPATHS.REGISTER} component={Register} />
        <Route exact path={EPATHS.USDC_AVAX} component={CryptoExchange} />
        {isAuthenticated ? <Route exact path={EPATHS.MY_PROFILE} component={MyProfile} />:<Redirect to={EPATHS.HOME} /> }
        {isAuthenticated ? <Route exact path={EPATHS.EDIT_PROFILE} component={Profile} />:<Redirect to={EPATHS.HOME} /> }
        {isAuthenticated ? <Route exact path={EPATHS.COUPON} component={Coupon} />:<Redirect to={EPATHS.HOME} /> }
        {isAuthenticated ? <Route exact path={EPATHS.COUPON_DETAIL} component={CouponDetail} />:<Redirect to={EPATHS.HOME} /> }
        {isAuthenticated ? <Route exact path={EPATHS.CREATE_OPTIONS} component={CreateOption} />:<Redirect to={EPATHS.HOME} /> }
        {isAuthenticated ? <Route exact path={EPATHS.CREATE_MULTIPLE} component={CreateMultiple} />:<Redirect to={EPATHS.HOME} /> }
        {/* {isAuthenticated ? <Route exact path={EPATHS.PROFILE_MANAGEMENT} component={UsersList} />:<Redirect to={EPATHS.HOME} /> } */}
        {/* {isAuthenticated ? <Route exact path={EPATHS.CREATE_PROFILE} component={CreateUser} />:<Redirect to={EPATHS.HOME} /> } */}
        {isAuthenticated ? <Route exact path={EPATHS.MY_NFTS} component={MyNFTs} />:<Redirect to={EPATHS.HOME} /> }
        {isAuthenticated ? <Route exact path={EPATHS.MY_COLLECTIONS} component={Collections} />:<Redirect to={EPATHS.HOME} /> }
        {isAuthenticated ? <Route exact path={EPATHS.MY_BIDS} component={MyBids} />:<Redirect to={EPATHS.HOME} /> }
        {isAuthenticated ? <Route exact path={EPATHS.EDIT_ITEM} component={EditItem} />:<Redirect to={EPATHS.HOME} /> }
        {isAuthenticated ? <Route exact path={EPATHS.MY_COLLECTION_HISTORY} component={CollectionHistory} />:<Redirect to={EPATHS.HOME} /> }
        {isAuthenticated ? <Route exact path={EPATHS.MY_COLLECTION_DETAIL} component={CollectionDetail} />:<Redirect to={EPATHS.HOME} /> }
        {isAuthenticated ? <Route exact path={EPATHS.NOTIFICATIONS} component={Notifications} />:<Redirect to={EPATHS.HOME} /> }
        {isAuthenticated ? <Route exact path={EPATHS.TRANSACTIONS} component={Transactions} />:<Redirect to={EPATHS.HOME} /> }
        {(isAuthenticated && (isSnapshot || isAdmin)) ? <Route exact path={EPATHS.GAIN} component={Gain} />:<Redirect to={EPATHS.HOME} /> }
        {(isAuthenticated && isAdmin) ? <Route exact path={EPATHS.SETTINGS} component={Settings} />:<Redirect to={EPATHS.HOME} /> }
        {(isAuthenticated && isAdmin) ? <Route exact path={EPATHS.ADMIN_ACTION} component={AdminAction} />:<Redirect to={EPATHS.HOME} /> }
        {/* {isAuthenticated ? <Route exact path={EPATHS.IN_GAME_WALLET} component={InGameWallet} />:<Redirect to={EPATHS.HOME} /> } */}
      </Switch>
    </Router>
    </LoadingOverlay>
    <ModalRegister
      open={showRegisterModal}
      onClose={()=> setShowRegisterModal(false) }
      showResend={async () => {
        await AuthService.resend({metamask: JSON.parse(stroageUser).metamask});
        setShowResendModal(true);
        setShowRegisterModal(false)
        }
      }
    />
    <CodeVerification
      open={showResendModal}
      onClose={() => setShowResendModal(false)}
      formData={formData}
      isRegister={false}
    />
  </div>;
}

export default Main;
